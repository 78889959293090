<template>
    <div class="app__block">
        <header-element>
            <button
                class=" button button--darkyellow main__cancel"
                @click="createItem"
            >
                Добавить карту
            </button>
        </header-element>

        <main-element>
            <template v-slot:h2>
                Выберите карту из списка
            </template>
            <card-list
                :inn="inn"
                :items="items"
                :controls="controls"
                @control:select="selectItem"
                @control:delete="deleteItem"
            />
            <modal
                :is-shown="showDelete"
                @hide="cancelDelete"
            >
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Удаление карты
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <p v-if="currentItem">
                        Вы действительно хотите удалить карту {{ currentItem.pan }}?
                    </p>
                </div>
                <div
                    slot="footer"
                    class="modal__panel"
                >
                    <button
                        class="modal__prev button button--white"
                        @click="cancelDelete"
                    >
                        Отмена
                    </button>
                    <button
                        class="modal__next button button--red"
                        @click="submitDelete"
                    >
                        Удалить
                    </button>
                </div>
            </modal>
            <modal
                :is-shown="!!result"
                @hide="hideResult"
            >
                <template v-if="result && result.ok === 'true'">
                    <h3
                        slot="header"
                        class="modal__header h2"
                    >
                        Карта успешно добавлена
                    </h3>
                </template>
                <template v-else-if="result && result.ok === 'false'">
                    <h3
                        slot="header"
                        class="modal__header h2"
                    >
                        Ошибка при добавлении карты
                    </h3>
                    <div
                        slot="content"
                        class="modal__content text"
                    >
                        <p v-if="result.detail">
                            {{ result.detail }}
                        </p>
                        <p v-if="result.error">
                            {{ result.error }}
                        </p>
                    </div>
                </template>
                <div
                    slot="footer"
                    class="modal__panel"
                >
                    <button
                        class="modal__submit button button--green"
                        @click="hideResult"
                    >
                        OK
                    </button>
                </div>
            </modal>

        </main-element>
    </div>
</template>

<script>
    import banking from '@/services/banking';
    import cardList from './card-list.vue';
    import modal from '@/components/modal.vue';
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';

    export default {
        components: {
            cardList,
            modal,
            headerElement,
            mainElement
        },
        props: {
            clientId: {
                type: String,
                required: true
            },
            inn: {
                type: String,
                required: true
            },
            card: {
                type: Object,
                default: undefined
            }
        },
        data() {
            return {
                idx: -1,
                action: null,
                items: [],
                value: this.card,
                controls: [
                    { event: 'select', label: 'Выбрать', color: 'green' },
                    { event: 'delete', label: 'Удалить', color: 'red' }
                ],
                result: this.$store.state.result
            };
        },
        computed: {
            persistId() {
                return __filename.replace(/\?.*$/, '');
            },
            persistKeys() {
                return ['value'];
            },
            showDelete() {
                return !!(this.action === 'delete' && this.currentItem);
            },
            currentItem() {
                return (this.idx !== -1) ? this.items[this.idx] : null;
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Получение клиента' });

            banking.tinkoff.getOrCreateClient(this.inn, this.clientId).then(() => {
                this.$store.dispatch('hideLoader');
                this.getItems();
            }).catch((error) => {
                this.$store.dispatch('hideLoader');
                const err = new Error('Не удалось получить клиента');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            });
        },
        methods: {
            getItems() {
                this.$store.dispatch('showLoader', { label: 'Загрузка карт' });

                banking.tinkoff.getCards(this.inn, this.clientId).then((items) => {
                    this.items = items;
                }).catch((error) => {
                    const err = new Error('Не удалось загрузить карты');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            selectItem(idx) {
                const item = this.items[idx];
                this.value = item;
                this.next();
            },
            deleteItem(idx) {
                this.action = 'delete';
                this.idx = idx;
            },
            submitDelete() {
                this.$store.dispatch('showLoader', { label: 'Удаление карты' });

                const item = this.items[this.idx];
                banking.tinkoff.deleteCard(this.inn, this.clientId, item.id).then(() => {
                    this.getItems();
                }).catch((error) => {
                    const err = new Error('Не удалось удалить карту');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.cancelDelete();
                    this.$store.dispatch('hideLoader');
                });
            },
            cancelDelete() {
                this.action = null;
                this.idx = -1;
            },
            createItem() {
                banking.tinkoff.createCard(this.inn, this.clientId, {}).then((data) => {
                    this.persistSave('card');
                    this.$store.dispatch('reloadStart');
                    document.location.href = data.url;
                }).catch((error) => {
                    const err = new Error('Не удалось добавить карту');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            hideResult() {
                this.result = undefined;
                delete this.$store.state.result;
            },
            prev() {
                this.$emit('prev', this.value);
            },
            next() {
                this.$emit('next', this.value);
            }
        }
    };
</script>
