<template>
    <div class="app__block">
        <header-element>
            <button
                class="button button--darkyellow main__cancel"
                :disabled="!isValid"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </header-element>

        <main-element>
            <template v-slot:h2>
                Укажите данные карты
            </template>
            <card-form
                ref="form"
                :values="card"
                class="main__section"
                @change="checkValid"
            />
        </main-element>
    </div>
</template>

<script>
    import cardForm from './card-form.vue';
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';

    export default {
        components: {
            cardForm,
            headerElement,
            mainElement
        },
        props: {
            card: {
                type: Object,
                default: undefined
            }
        },
        data() {
            return {
                isValid: false
            };
        },
        mounted() {
            this.checkValid();
        },
        methods: {
            checkValid() {
                this.isValid = this.$refs.form.validate();
            },
            serialize() {
                return this.$refs.form.serialize();
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            }
        }
    };
</script>
