<template>
    <div class="app__transfer">
        <transfer
            v-if="isValid"
            :transaction-id="transactionId"
            :client-id="clientId"
            :amount="amount"
            :inn="inn"
            :bik="bik"
            :biks="biks"
            :comment-data="commentData"
        />
    </div>
</template>

<script>
    import transfer from '@/views/operations/transfer/main.vue';

    export default {
        components: {
            transfer
        },
        data() {
            return {
                isValid: false
            };
        },
        computed: {
            query() {
                return this.$router.currentRoute.query;
            },
            user() {
                return {
                    id: this.query.worker_id,
                    username: 'merchant' + this.query.worker_id,
                    last_name: 'Товаровед ', /*  + this.query.worker_id */
                    occupation: 'Кассир-товаровед'
                };
            },
            transactionId() {
                return this.query.transaction_id;
            },
            clientId() {
                return this.query.client_id;
            },
            amount() {
                return parseFloat(this.query.amount.replace(',', '.'));
            },
            inn() {
                return this.query.organization_inn;
            },
            biks() {
                return (this.query['biks[]'] instanceof Array) ? this.query['biks[]'] : [ this.query['biks[]'] ];
            },
            bik() {
                return this.query.bik || undefined;
            },
            commentData() {
                return this.query.comment;
            },
            check() {
                return Number(this.query.check);
            }
        },
        created() {
            this.checkParams();
            this.$store.dispatch('authUser', this.user);
        },
        methods: {
            checkParams() {
                const required = ['worker_id', 'transaction_id', 'client_id', 'amount', 'organization_inn', 'biks[]', 'check'];
                for (let name of required) {
                    if (!(name in this.query)) {
                        throw new Error(`Параметр ${name} обязателен`);
                    }
                }
                this.isValid = true;
            }
        }
    };
</script>

