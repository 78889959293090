<template>
    <div class="app__block">
        <header-element>
            <button
                class="button button--darkyellow main__cancel"
                :disabled="!isConfirmed"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </header-element>

        <main-element>
            <template v-slot:h2>
                Подтвердите перевод
            </template>

            <div class="form">
                <div class="form__field field field--type_text field--inline">
                    <div class="field__label">
                        Распечатайте заявление
                    </div>
                    <div class="field__input">
                        <button
                            class="inpton inpton--darkyellow"
                            @click="printForm"
                        >
                            Распечатать
                        </button>
                    </div>
                </div>

                <field
                    v-model="isConfirmed"
                    :inline="true"
                    :disabled="!isPrinted"
                    type="boolean"
                    label="Получен подписанный экземпляр заявления на выдачу"
                    class="form__field"
                />
            </div>

        </main-element>
    </div>

</template>

<script>
    import _defaults from 'lodash/defaults';
    import { banking, printforms } from '@/services';
    import field from '@/components/field.vue';
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';

    const PRINT_DELAY = 500;

    export default {
        components: {
            field,
            headerElement,
            mainElement
        },
        props: {
            transactionId: {
                type: String,
                required: true
            },
            card: {
                type: Object,
                required: true
            },
            amount: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                isPrinted: false,
                isConfirmed: false
            };
        },
        methods: {
            printForm() {
                this.$store.dispatch('showLoader', { label: 'Генерация печатной формы' });

                banking.getTransaction(this.transactionId).then((data) => {
                    let formData = this.prepareFormData(data);
                    return printforms.generate('transfer-application', 'html', formData);
                }).then((data) => {
                    const content = new Blob([data], { type: 'text/html' });
                    const url = window.URL.createObjectURL(content);
                    const iframe = document.createElement('iframe');
                    iframe.src = url;
                    document.body.appendChild(iframe);
                    setTimeout(() => {
                        iframe.contentWindow.focus();
                        iframe.contentWindow.print();
                        document.body.removeChild(iframe);
                        this.isPrinted = true;
                    }, PRINT_DELAY);
                }).catch((error) => {
                    const err = new Error('Не удалось сгенерировать печатную форму');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            prepareFormData(data) {
                let params = _defaults({
                    card: this.card,
                    amount: this.amount
                }, data);
                params.amount_rub = Math.floor(params.amount);
                params.amount_kop = Math.round((params.amount - params.amount_rub) * 100);
                return params;
            },
            prev() {
                this.$emit('prev', this.isConfirmed);
            },
            next() {
                this.$emit('next', this.isConfirmed);
            }
        }
    };
</script>
