<template>
    <div class="entity-list">
        <div
            v-if="items.length"
            class="entity-list__list"
        >
            <div
                v-for="(item, idx) in items"
                class="entity-list__item entity-item"
            >
                <div class="entity-item__head entity-item__header">
                    {{ item.pan }}
                </div>
                <div
                    v-if="controls.length"
                    class="entity-item__foot entity-item__panel"
                >
                    <div
                        v-for="control in controls"
                        class="entity-item__panel-item"
                    >
                        <a
                            href="#"
                            class="a"
                            :class="control.color ? 'a--' + control.color : ''"
                            @click.prevent="callControl(control.event, idx)"
                        >
                            {{ control.label }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="entity-list__empty"
        >
            <slot name="empty">
                Список пуст
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            inn: {
                type: String,
                required: true
            },
            items: {
                type: Array,
                default() { return []; }
            },
            controls: {
                type: Array,
                default() { return []; }
            }
        },
        methods: {
            callControl(event, idx) {
                this.$emit('control', event, idx);
                this.$emit('control:' + event, idx);
            }
        }
    };
</script>
