import Vue from 'vue';
import VueRouter from 'vue-router';
import index from '@/views/transfer/index.vue';
import persist from '@/views/transfer/persist.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'index',
        component: index
    }, {
        path: '/persist/:id',
        name: 'persist',
        component: persist
    }
];

const router = new VueRouter({
    mode: (process.env.NODE_ENV === 'production') ? 'history' : 'hash',
    linkActiveClass: 'is-subactive',
    linkExactActiveClass: 'is-active',
    routes
});

export default router;
