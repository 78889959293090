<template>
    <div class="info">
        <div
            v-for="fieldset in struct.fieldsets"
            class="info__fieldset"
            :class="fieldset.className"
        >
            <div
                v-if="fieldset.header"
                class="info__header h3"
            >
                {{ fieldset.header }}
            </div>
            <slot
                v-for="field in fieldset.fields"
                :name="field.name"
            >
                <value
                    :value="values[field.name]"
                    :label="field.label"
                    :type="field.type"
                    :options="opt[field.name]"
                    :inline="inline"
                    class="info__field"
                />
            </slot>
        </div>

        <div
            v-if="struct.fields.length"
            class="info__fieldset"
        >
            <slot
                v-for="field in struct.fields"
                :name="field.name"
            >
                <value
                    :value="values[field.name]"
                    :label="field.label"
                    :type="field.type"
                    :options="opt[field.name]"
                    :inline="inline"
                    class="info__field"
                />
            </slot>
        </div>
    </div>
</template>

<script>
    import value from './value.vue';

    export default {
        components: {
            value
        },
        props: {
            values: {
                type: Object,
                default: () => { return {}; }
            }
        },
        data() {
            return {
                fields: [],
                fieldsets: [],
                options: {},
                inline: true
            };
        },
        computed: {
            struct() {
                let fields = [],
                    fieldsets = [],
                    reserved = {};

                for (let fieldset of this.fieldsets) {
                    let items = [];
                    for (let fieldName of fieldset.names) {
                        if (reserved[fieldName]) {
                            console.warn(`Поле ${fieldName} уже было использовано`);
                        }
                        let field = this.fieldRel[fieldName];
                        if (field) {
                            if (typeof this.values[fieldName] !== 'undefined') {
                                items.push(field);
                            }
                            reserved[fieldName] = true;
                        } else {
                            console.warn(`Поле ${fieldName} не найдено`);
                        }
                    }
                    if (items.length) {
                        fieldset.fields = items;
                        fieldsets.push(fieldset);
                    }
                }

                for (let field of this.fields) {
                    if (!reserved[field.name]) {
                        if (typeof this.values[field.name] !== 'undefined') {
                            fields.push(field);
                        }
                    }
                }

                return {
                    fieldsets,
                    fields
                };
            },
            fieldRel() {
                let rel = {};
                for (let field of this.fields) {
                    rel[field.name] = field;
                }
                return rel;
            },
            opt() {
                let options = {};
                for (let field of this.fields) {
                    if (field.options) {
                        options[field.name] = field.options;
                    } else if (this.options[field.name]) {
                        options[field.name] = this.options[field.name];
                    }
                }
                return options;
            }
        }
    };
</script>
