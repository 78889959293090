<template>
    <div class="app__block">
        <header-element>
            <button
                class="button button--darkyellow main__cancel"
                @click="submit"
            >
                Отправить платеж
            </button>
        </header-element>

        <main-element>
            <template v-slot:h2>
                Отправьте платеж
            </template>
            <payment-info :values="values" />
        </main-element>
    </div>
</template>

<script>
    import banking from '@/services/banking';
    import paymentInfo from './payment-info.vue';
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';

    export default {
        components: {
            paymentInfo,
            headerElement,
            mainElement
        },
        props: {
            inn: {
                type: String,
                required: true
            },
            card: {
                type: Object,
                required: true
            },
            amount: {
                type: Number,
                required: true
            },
            code: {
                type: String,
                required: true
            },
            orderId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                payment: undefined,
            };
        },
        computed: {
            values() {
                return {
                    card_pan: this.card.pan,
                    amount: this.amount
                };
            }
        },
        methods: {
            prev() {
                this.$emit('prev', this.payment);
            },
            next() {
                this.$emit('next', this.payment);
            },
            submit() {
                this.$store.dispatch('showLoader', { label: 'Выполнение платежа' });
                banking.chelinvest.executePayment(this.inn, {
                    order_id: this.orderId,
                    code: this.code
                }).then((data) => {
                    this.payment = data;
                    this.$store.dispatch('hideLoader');
                    this.next();
                }).catch((error) => {
                    if (error.response && error.response.data) {
                        this.errors = error.response.data;
                        if (error.response.data.detail) {
                            this.$emit('re-send', error.response.data.detail);
                        }
                    } else {
                        const err = new Error('Не выполнить платёж');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
        }
    };
</script>
