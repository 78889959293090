<template>
    <div class="app__bank">
        <card
            v-if="isStage('card') && phone"
            :client-id="clientId"
            :inn="inn"
            :card="card"
            :phone="phone"
            @prev="prevCard"
            @next="nextCard"
        />
        <verify
            v-if="isStage('verify')"
            :transaction-id="transactionId"
            :client-id="clientId"
            :comment-data="commentData"
            :bik="bik"
            :inn="inn"
            :amount="amount"
            :phone="phone"
            :card="card"
            @prev="prevVerify"
            @next="nextVerify"
            @code="setCode"
            @order-id="requestId"
        />
        <confirm
            v-if="isStage('confirm')"
            :transaction-id="transactionId"
            :amount="amount"
            :card="card"
            @prev="prevConfirm"
            @next="nextConfirm"
        />
        <payment
            v-if="isStage('payment')"
            :inn="inn"
            :card="card"
            :amount="amount"
            :code="code"
            :order-id="orderId"
            @prev="prevPayment"
            @next="nextPayment"
            @re-send="gotoResend"
        />
        <payment-resend
            v-if="isStage('resend')"
            :inn="inn"
            :order-id="orderId"
            :payment-resend="payment_resend"
            @prev="prevResend"
            @next="nextResend"
        />
    </div>
</template>

<script>
    import { merchants } from '@/services';
    import card from './card.vue';
    import verify from './verify.vue';
    import confirm from '../confirm.vue';
    import payment from './payment.vue';
    import paymentResend from './payment-resend.vue';

    export default {
        components: {
            card,
            verify,
            confirm,
            payment,
            paymentResend
        },
        props: {
            transactionId: {
                type: String,
                required: true
            },
            clientId: {
                type: String,
                required: true
            },
            bik: {
                type: String,
                required: true
            },
            inn: {
                type: String,
                required: true
            },
            amount: {
                type: Number,
                required: true
            },
            commentData: {
                type: String
            }
        },
        data() {
            return {
                phone: undefined,
                code: '',
                orderId: '',
                stage: 'card',
                card: undefined,
                verify: undefined,
                confirm: undefined,
                payment: undefined,
                payment_resend: undefined
            };
        },
        computed: {
            persistId() {
                return __filename.replace(/\?.*$/, '');
            },
            workerId() {
                return this.$router.currentRoute.query.worker_id;
            },
            requestData() {
                return {
                    inn: this.inn,
                    type: '001'
                };
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Получение телефона товароведа' });
            merchants.requestPhone(this.workerId, this.requestData).then((data) => {
                this.phone = data.phone;
            }).catch((error) => {
                this.$store.dispatch('hideLoader');
                const err = new Error('Не удалось получить телефон товароведа');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            })/*.finally(() => {
                this.$store.dispatch('hideLoader');
            })*/;
        },
        methods: {
            setCode(value) {
              this.code = value
            },
            requestId(value) {
              this.orderId = value;
            },
            isStage(stage) {
                return this.stage === stage;
            },
            goTo(stage) {
                this.stage = stage;
            },
            serialize() {
                return {
                    card: this.card,
                    verify: this.verify,
                    confirm: this.confirm,
                    payment: this.payment,
                    payment_resend: this.payment_resend
                };
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            },
            prevCard(value) {
                this.card = value;
                this.prev();
            },
            nextCard(value) {
                this.card = value;
                this.goTo('verify');
            },
            prevVerify(value) {
                this.verify = value;
                this.goTo('card');
            },
            nextVerify(value) {
                this.verify = value;
                this.goTo('confirm');
            },
            prevConfirm(value) {
                this.confirm = value;
                this.goTo('verify');
            },
            nextConfirm(value) {
                this.confirm = value;
                this.goTo('payment');
            },
            prevPayment(value) {
                this.payment = value;
                this.goTo('confirm');
            },
            nextPayment(value) {
                this.payment = value;
                this.next();
            },
            gotoResend(value) {
                this.payment_resend = value;
                this.goTo('resend');
            },
            prevResend(value) {
                this.payment_resend = value;
                this.goTo('payment');
            },
            nextResend(value) {
                this.payment = value;
                this.next();
            }
        }
    };
</script>
