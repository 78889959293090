<template>
    <div class="app__block">
        <header-element>
            <button
                class="main__submit button button--yellow"
                :disabled="!isVerified"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </header-element>

        <main-element>
            <template v-slot:h2>
                Верифицируйте ПЭП
            </template>
            <div class="form">
                <div
                    v-if="isVerified"
                    class="form__field field field--type_text field--inline"
                >
                    <div class="field__label">
                        Верификация
                    </div>
                    <div class="field__input">
                        <button
                            :disabled="true"
                            class="inpton inpton--darkyellow"
                        >
                            Пройдена
                        </button>
                    </div>
                </div>
                <div
                    v-else-if="isRequested"
                    class="form__field field field--type_verify field--inline"
                >
                    <div class="field__label">
                        Верификация
                    </div>
                    <div class="field__input">
                        <input
                            v-model="code"
                            type="text"
                            class="input"
                        >
                        <button
                            :disabled="!isValid"
                            class="inpton inpton--darkyellow"
                            @click="confirm"
                        >
                            Верифицировать
                        </button>
                        <button
                            class="inpton inpton--yellow"
                            :disabled="disabled"
                            @click="reSend"
                        >
                            Отправить снова {{ currentTime }}
                        </button>
                    </div>
                </div>
                <div
                    v-else
                    class="form__field field field--type_text field--inline"
                >
                    <div class="field__label">
                        Верификация
                    </div>
                    <div class="field__input">
                        <button
                            class="inpton inpton--darkyellow"
                            :disabled="disabled"
                            @click="request"
                        >
                            Отправить код {{ currentTime }}
                        </button>
                    </div>
                </div>

                <div
                    v-for="message in messages"
                    class="form__message form__message--error"
                >
                    {{ message }}
                </div>
            </div>
        </main-element>
    </div>
</template>

<script>
    import { merchants } from '@/services';
    import banking from '@/services/banking';
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';

    export default {
        components: {
            headerElement,
            mainElement
        },
        props: {
            clientId: {
                type: String,
                required: true
            },
            transactionId: {
                type: String,
                required: true
            },
            bik: {
                type: String,
                required: true
            },
            inn: {
                type: String,
                required: true
            },
            card: {
                type: Object,
                required: true
            },
            amount: {
                type: Number,
                required: true
            },
            phone: {
                type: String,
                required: true
            },
            commentData: {
                type: String
            }
        },
        data() {
            return {
                isVerified: false,
                requestId: null,
                code: '',
                errors: {},
                messages: [],
                disabled: false,
                limitTime: 30,
                currentTime: null,
                timeout: null
            };
        },
        computed: {
            isRequested() {
                return !!this.requestId;
            },
            isValid() {
                return this.isRequested && this.code !== '';
            },
            valid() {
                if (this.isRequested) {
                    return {
                        code: this.code !== ''
                    };
                }
                return {};
            },
            invalid() {
                if (this.isRequested) {
                    return {
                        code: this.code === ''
                    };
                }
                return {};
            },
            reSendData() {
                return {
                    order_id: this.requestId
                };
            }
        },
        methods: {
            request() {
                this.$store.dispatch('showLoader', { label: 'Запрос ПЭП' });

                this.timerStart();

                this.errors = {};
                this.messages = [];

                banking.chelinvest.createPayment(this.inn, {
                    client_id: this.clientId,
                    phone: this.phone,
                    token:this.card.token,
                    bik: this.bik,
                    amount:this.amount,
                    card_pan: this.card.pan,
                    transaction_id: this.transactionId,
                    description: this.commentData
                }).then((data) => {
                    this.requestId = data.orderId;
                    this.$emit('order-id', data.orderId);
                }).catch((error) => {
                    if (error.response && error.response.data) {
                        this.errors = error.response.data;
                        if (error.response.data.detail) {
                            this.messages = [ error.response.data.detail ];
                        }
                    } else {
                        const err = new Error('Не удалось запросить ПЭП');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            confirm() {
                this.isVerified = true;
                this.$emit('code', this.code);
            },
            reSend() {
                this.$store.dispatch('showLoader', { label: 'Повторный запрос ПЭП' });
                this.errors = {};
                this.messages = [];

                this.timerStart();

                banking.chelinvest.resendCode(this.inn, this.reSendData).then((data) => {
                }).catch((error) => {
                    if (error.response && error.response.data) {
                        this.errors = error.response.data;
                        if (error.response.data.detail) {
                            this.messages = [ error.response.data.detail ];
                        }
                    } else {
                        const err = new Error('Не удалось запросить ПЭП');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            timerStart() {
                this.disabled = true;
                this.currentTime = this.limitTime;
                this.timeout = setInterval(() => {
                    this.currentTime--;
                    if (this.currentTime < 1) {
                        clearTimeout(this.timeout);
                        this.disabled = false;
                        this.currentTime = null;
                    }
                }, 1000);
            },

            prev() {
                this.$emit('prev', this.isVerified);
            },
            next() {
                this.$emit('next', this.isVerified);
            }
        }
    };
</script>
