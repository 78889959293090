<template>
    <div class="bank-select">
        <div
            v-if="items.length"
            class="bank-select__list"
        >
            <div
                v-for="item in items"
                class="bank-select__item"
                :class="{ 'is-active': isActive(item) }"
                @click="select(item)"
            >
                <div
                    class="bank-select__icon"
                    :style="getStyle(item)"
                />
                <div class="bank-select__name">
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div
            v-else
            class="bank-select__empty"
        >
            <slot name="empty">
                Список пуст
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            items: {
                type: Array,
                default() { return []; }
            }
        },
        methods: {
            isActive(item) {
                return this.value === item.code;
            },
            getStyle(item) {
                if (item.image) {
                    return {
                        backgroundImage: 'url(\'' + item.image + '\')'
                    };
                }
                return {};
            },
            select(item) {
                let bank = this.isActive(item) ? '' : item;
                this.$emit('change', bank.code);
                // this.$store.dispatch('createBtnNext', {fnc: "nextBank", val: bank});
            }
        }
    };
</script>
