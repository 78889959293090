<script>
    import abstractForm from '@/components/abstract-form.vue';

    const fields = [
        { name: 'pan', type: 'mask', label: 'Номер карты', required: true, mask: '9999 9999 9999 9{1,4} 9{0,4}' }
    ];

    const checks = {
        pan: { required: true }
    };

    export default {
        extends: abstractForm,
        data() {
            return {
                fields,
                checks,
                inline: true
            };
        }
    };
</script>
