<script>
    import abstractInfo from '@/components/abstract-info.vue';

    const fields = [
        { name: 'card_pan', type: 'text', label: 'Номер карты', required: true },
        { name: 'amount', type: 'number', label: 'Сумма перевода', required: true }
    ];

    export default {
        extends: abstractInfo,
        data() {
            return {
                fields,
                inline: true
            };
        }
    };
</script>
