<template>
    <div class="app__block">
        <header-element>
            <button
                class="button button--darkyellow main__cancel"
                :disabled="!isValid"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </header-element>

        <main-element>
            <template v-slot:h2>Выберите банк для перевода</template>
            <bank-select
                v-model="value"
                :items="items"
            />
        </main-element>
    </div>
</template>

<script>
    import _includes from 'lodash/includes';
    import _find from 'lodash/find';
    import _filter from 'lodash/filter';
    import { banking } from '@/services';
    import bankSelect from '@/components/bank-select.vue';
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';

    export default {
        components: {
            bankSelect,
            headerElement,
            mainElement
        },
        props: {
            inn: {
                type: String,
                required: true
            },
            biks: {
                type: Array,
                default: undefined
            },
            bik: {
                type: String,
                default: undefined
            },
            bank: {
                type: Object,
                default: undefined
            }
        },
        data() {
            return {
                value: this.bank && this.bank.code,
                items: []
            };
        },
        computed: {
            isPrev() {
                return false;
            },
            isValid() {
                return !!this.value;
            },
            currentItem() {
                return _find(this.items, { code: this.value });
            },
            check() {
                return Number(this.$router.currentRoute.query.check);
            }
        },
        created() {
            this.checkLinkValid();
            this.$store.dispatch('showLoader', { label: 'Загрузка банков' });

            banking.getBanks({
                inn: this.inn
            }).then((items) => {
                if (this.biks !== undefined) {
                    this.items = _filter(items, (el) => { return _includes(this.biks, el.bik); });
                } else {
                    this.items = items;
                }
                this.defineDefault();
            }).catch((error) => {
                const err = new Error('Не удалось загрузить банки');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        methods: {
            defineDefault() {
                if (!this.value && this.bik) {
                    const bank = _find(this.items, { bik: this.bik });
                    if (bank) {
                        this.value = bank.code;
                    }
                }
            },
            checkLinkValid() {
                var dt = new Date();
                const unix_now = Math.floor(dt.getTime() / 1000);
                dt.setHours( dt.getHours() - 1 );
                const unix_before = Math.floor(dt.getTime() / 1000);
                dt.setHours( dt.getHours() +1 );
                dt.setMinutes( dt.getMinutes() + 10);
                const unix_new_now = Math.floor(dt.getTime() / 1000);

                if ((window.location.hostname !== 'localhost') && ((unix_before > this.check) || (this.check > unix_new_now))) {
                    throw new Error(`Ваша ссылка очень старая!`);
                }
            },
            prev() {
                this.$emit('prev', this.currentItem);
            },
            next() {
                this.$emit('next', this.currentItem);
            }
        }
    };
</script>
