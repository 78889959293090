<template>
    <div class="app__block">
        <header-element>

        </header-element>

        <main-element>
            <template v-slot:h2>
                Отправить код повторно
            </template>
            <div class="form">
                <div class="form__field field field--type_verify field--inline">
                    <div class="field__label">
                        Верификация
                    </div>
                    <div class="field__input">
                        <input
                            v-model="code"
                            type="text"
                            class="input"
                        />
                        <button
                            :disabled="!isValid"
                            class="inpton inpton--green"
                            @click="confirm"
                        >
                            Верифицировать
                        </button>
                        <button
                            class="inpton inpton--yellow"
                            :disabled="disabled"
                            @click="reSend"
                        >
                            Отправить снова {{ currentTime }}
                        </button>
                    </div>
                </div>
            </div>
            <div
                v-for="message in messages"
                class="form__message form__message--error"
            >
                {{ message }}
            </div>
        </main-element>
    </div>
</template>

<script>
    import banking from '@/services/banking';
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';

    export default {
        components: {
            headerElement,
            mainElement
        },
        props: {
            inn: {
                type: String,
                required: true
            },
            orderId: {
                type: String,
                required: true
            },
            paymentResend: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                isVerified: false,
                code: '',
                errors: {},
                messages: [],
                payment: undefined,
                disabled: false,
                limitTime: 30,
                currentTime: null,
                timeout: null
            };
        },
        created() {
            this.messages = [ this.paymentResend ];
        },
        computed: {
            isValid() {
                return this.code !== '';
            },
        },
        methods: {
            prev() {
                this.$emit('prev', this.payment);
            },
            next() {
                this.$emit('next', this.payment);
            },
            confirm() {
                this.errors = {};
                this.messages = [];
                banking.chelinvest.executePayment(this.inn, {
                    order_id: this.orderId,
                    code: this.code
                }).then((data) => {
                    this.payment = data;
                    this.next();
                }).catch((error) => {
                    if (error.response && error.response.data) {
                        this.errors = error.response.data;
                        if (error.response.data.detail && error.response.data.status !== 0) {
                            this.messages = [ error.response.data.detail ];
                        }
                    } else {
                        const err = new Error('Не удалось подтвердить выплату');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                });
            },
            reSend() {
                this.$store.dispatch('showLoader', { label: 'Повторный запрос ПЭП' });
                this.errors = {};
                this.messages = [];

                this.timerStart();

                banking.chelinvest.resendCode(this.inn, {order_id: this.orderId}).catch((error) => {
                    if (error.response && error.response.data) {
                        this.errors = error.response.data;
                        if (error.response.data.detail) {
                            this.messages = [ error.response.data.detail ];
                        }
                    } else {
                        const err = new Error('Не удалось запросить ПЭП');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            timerStart() {
                this.disabled = true;
                this.currentTime = this.limitTime;
                this.timeout = setInterval(() => {
                    this.currentTime--;
                    if (this.currentTime < 1) {
                        clearTimeout(this.timeout);
                        this.disabled = false;
                        this.currentTime = null;
                    }
                }, 1000);
            },
        }
    };
</script>
