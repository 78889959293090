<template>
    <div class="app">
        <router-view />
        <app-error />
        <app-loader />
    </div>
</template>

<style lang="postcss">
    @import './assets/fianit_workplace/css/transfer.pcss';
</style>

<script>
    import appError from '@/components/app-error.vue';
    import appLoader from '@/components/app-loader.vue';

    export default {
        components: {
            appError,
            appLoader
        },
        created() {
            window.onerror = (message, source, lineno, colno, err) => {
                console.error('Window error', err.message);
                this.$store.dispatch('showError', { err });
            };
        },
        errorCaptured(err, vm, info) {
            console.error(info);
            console.error('Local error', err.message);
            this.$store.dispatch('showError', { err, vm, info });
            return false;
        }
    };
</script>
