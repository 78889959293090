<template>
    <div class="app__block">
        <header-element>
            <button
                class="button button--darkyellow main__cancel"
                :disabled="!isVerified"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </header-element>

        <main-element>
            <template v-slot:h2>
                Верифицируйте ПЭП
            </template>

            <div class="form">
                <div
                    v-if="isVerified"
                    class="form__field field field--type_text field--inline"
                >
                    <div class="field__label">
                        Верификация
                    </div>
                    <div class="field__input">
                        <button
                            :disabled="true"
                            class="inpton inpton--darkred"
                        >
                            Пройдена
                        </button>
                    </div>
                </div>
                <div
                    v-else-if="isRequested"
                    class="form__field field field--type_verify field--inline"
                >
                    <div class="field__label">
                        Верификация
                    </div>
                    <div class="field__input">
                        <input
                            v-model="code"
                            type="text"
                            class="input"
                            :class="{ 'is-valid': valid.code, 'is-invalid': invalid.code, 'is-error': errors.code }"
                        >
                        <button
                            :disabled="!isValid"
                            class="inpton inpton--darkyellow"
                            @click="confirm"
                        >
                            Верифицировать
                        </button>
                        <button
                            class="inpton inpton--yellow"
                            :disabled="disabled"
                            @click="request"
                        >
                            Отправить снова {{ currentTime }}
                        </button>
                    </div>
                </div>
                <div
                    v-else
                    class="form__field field field--type_text field--inline"
                >
                    <div class="field__label">
                        Верификация
                    </div>
                    <div class="field__input">
                        <button
                            class="inpton inpton--purple"
                            :disabled="disabled"
                            @click="request"
                        >
                            Отправить код {{ currentTime }}
                        </button>
                    </div>
                </div>

                <div
                    v-for="message in messages"
                    class="form__message form__message--error"
                >
                    {{ message }}
                </div>
            </div>

        </main-element>
    </div>
</template>

<script>
    import { merchants } from '@/services';
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';

    export default {
        components: {
            headerElement,
            mainElement
        },
        props: {
            inn: {
                type: String,
                required: true
            },
            card: {
                type: Object,
                required: true
            },
            amount: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                isVerified: false,
                requestId: null,
                code: '',
                errors: {},
                messages: [],
                disabled: false,
                limitTime: 30,
                currentTime: null,
                timeout: null
            };
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
            isRequested() {
                return !!this.requestId;
            },
            isValid() {
                return this.isRequested && this.code !== '';
            },
            valid() {
                if (this.isRequested) {
                    return {
                        code: this.code !== ''
                    };
                }
                return {};
            },
            invalid() {
                if (this.isRequested) {
                    return {
                        code: this.code === ''
                    };
                }
                return {};
            },
            requestData() {
                const str = this.card.pan.replace(/\D/g, '');
                const card_pan = `${str.slice(0, 6)}***${str.slice(-4)}`;
                return {
                    inn: this.inn,
                    type: '001',
                    text: `Перевод ${this.amount} руб. на карту ${card_pan}.`
                };
            },
            confirmData() {
                return {
                    request_id: this.requestId,
                    code: this.code
                };
            }
        },
        methods: {
            request() {
                this.$store.dispatch('showLoader', { label: 'Запрос ПЭП' });

                this.timerStart();

                this.errors = {};
                this.messages = [];

                merchants.requestSES(this.user.id, this.requestData).then((data) => {
                    this.requestId = data.request_id;
                }).catch((error) => {
                    if (error.response && error.response.data) {
                        this.errors = error.response.data;
                        if (error.response.data.detail) {
                            this.messages = [ error.response.data.detail ];
                        }
                    } else {
                        const err = new Error('Не удалось запросить ПЭП');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            confirm() {
                this.$store.dispatch('showLoader', { label: 'Подтверждение' });

                this.errors = {};
                this.messages = [];

                merchants.confirmSES(this.user.id, this.confirmData).then(() => {
                    this.isVerified = true;
                }).catch((error) => {
                    if (error.response && error.response.data) {
                        this.errors = error.response.data;
                        if (error.response.data.detail) {
                            this.messages = [ error.response.data.detail ];
                        }
                    } else {
                        const err = new Error('Не удалось подтвердить ПЭП');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            timerStart() {
                this.disabled = true;
                this.currentTime = this.limitTime;
                this.timeout = setInterval(() => {
                    this.currentTime--;
                    if (this.currentTime < 1) {
                        clearTimeout(this.timeout);
                        this.disabled = false;
                        this.currentTime = null;
                    }
                }, 1000);
            },
            prev() {
                this.$emit('prev', this.isVerified);
            },
            next() {
                this.$emit('next', this.isVerified);
            }
        }
    };
</script>
