<template>
    <div
        class="value"
        :class="className"
    >
        <div class="value__label">
            <slot name="label">
                {{ label }}
            </slot>
        </div>
        <div class="value__value">
            <slot name="value">
                <template v-if="isEmpty">
                    не заполнено
                </template>
                <template v-else>
                    <template v-if="type === 'checkbox'">
                        <template v-for="el in val">
                            &bull; {{ el }}<br>
                        </template>
                    </template>
                    <template v-else-if="type === 'textarea'">
                        <p v-html="val" />
                    </template>
                    <template v-else-if="type === 'date'">
                        {{ val | date('DD.MM.YYYY') }}
                    </template>
                    <template v-else-if="type === 'datetime'">
                        {{ val | date('HH:mm DD.MM.YYYY') }}
                    </template>
                    <template v-else>
                        {{ val }}
                    </template>
                </template>
            </slot>
        </div>
        <div class="value__comment">
            <slot name="comment">
                <div
                    v-if="comment"
                    class="value__tooltip tooltip"
                >
                    {{ comment }}
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
    import _map from 'lodash/map';

    export default {
        props: {
            value: {
                type: [String, Number, Boolean, Array, Object],
                required: true
            },
            type: {
                type: String,
                default: 'text'
            },
            precision: {
                type: Number,
                default: undefined
            },
            label: {
                type: String,
                required: true
            },
            options: {
                type: Array,
                default() { return []; }
            },
            inline: {
                type: Boolean,
                default: false
            },
            comment: {
                type: String,
                default: ''
            }
        },
        computed: {
            className() {
                let value = 'value--type_' + this.type;
                if (this.inline) {
                    value += ' value--inline';
                }
                if (this.isEmpty) {
                    value += ' value--empty';
                }
                return value;
            },
            isEmpty() {
                if (this.value === undefined || this.value === null) {
                    return true;
                }

                if (this.type === 'checkbox') {
                    return !this.value.length;
                } else {
                    return this.value === '';
                }
            },
            opt() {
                let options = {};
                for (let option of this.options) {
                    options[option.value] = option.name;
                }
                return options;
            },
            val() {
                if (this.type === 'checkbox') {
                    return _map(this.value, (el) => {
                        return this.opt[el] || el;
                    });
                } else if (this.type === 'select' || this.type === 'radio') {
                    return this.opt[this.value] || this.value;
                } else if (this.type === 'number') {
                    return (this.precision !== undefined) ? this.value.toFixed(this.precision) : this.value;
                } else if (this.type === 'boolean') {
                    return this.value ? 'Да' : 'Нет';
                } else if (this.type === 'textarea') {
                    return this.value.replace(/\n/, '<br>');
                }

                return this.value;
            }
        }
    };
</script>
