<template>
    <div class="app__banks">
        <banks
            v-if="isStage('banks')"
            :inn="inn"
            :bik="bik"
            :biks="biks"
            :bank="bank"
            @next="nextBank"
            @prev="prevBank"
        />
        <tinkoff
            v-if="isStage('transfer') && isBank('tinkoff')"
            :transaction-id="transactionId"
            :client-id="clientId"
            :comment-data="commentData"
            :bik="bank.bik"
            :inn="inn"
            :amount="amount"
            @next="nextTransfer"
            @prev="prevTransfer"
        />
        <transkapital
            v-if="isStage('transfer') && isBank('transkapital')"
            :transaction-id="transactionId"
            :client-id="clientId"
            :bik="bank.bik"
            :inn="inn"
            :amount="amount"
            @next="nextTransfer"
            @prev="prevTransfer"
        />
        <chelinvest
            v-if="isStage('transfer') && isBank('chelinvest')"
            :transaction-id="transactionId"
            :client-id="clientId"
            :comment-data="commentData"
            :bik="bank.bik"
            :inn="inn"
            :amount="amount"
            @next="nextTransfer"
            @prev="prevTransfer"
        />
        <alfabank
            v-if="isStage('transfer') && isBank('alfabank')"
            :transaction-id="transactionId"
            :client-id="clientId"
            :comment-data="commentData"
            :bik="bank.bik"
            :inn="inn"
            :amount="amount"
            @next="nextTransfer"
            @prev="prevTransfer"
        />
        <sberbank
            v-if="isStage('transfer') && isBank('sberbank')"
            :transaction-id="transactionId"
            :client-id="clientId"
            :comment-data="commentData"
            :bik="bank.bik"
            :inn="inn"
            :amount="amount"
            @next="nextTransfer"
            @prev="prevTransfer"
        />

        <result
            :payment="payment"
            :is-ok="isOk"
        />
    </div>
</template>

<script>
    import banking from '@/services/banking';
    import banks from './banks.vue';
    import tinkoff from './tinkoff/index.vue';
    import transkapital from './transkapital/index.vue';
    import chelinvest from './chelinvest/index.vue';
    import alfabank from './alfabank/index.vue';
    import sberbank from './sberbank/index.vue';
    import result from './result.vue';

    const SUCCESS_STATUS = 0;
    const PROCESS_STATUS = 2;

    export default {
        components: {
            banks,
            tinkoff,
            transkapital,
            chelinvest,
            alfabank,
            sberbank,
            result
        },
        props: {
            transactionId: {
                type: String,
                required: true
            },
            clientId: {
                type: String,
                required: true
            },
            inn: {
                type: String,
                required: true
            },
            amount: {
                type: Number,
                required: true
            },
            biks: {
                type: Array,
                required: true
            },
            bik: {
                type: String,
                default: undefined
            },
            pan: {
                type: String,
                default: undefined
            },
            commentData: {
                type: String,
                default: undefined
            }
        },
        data() {
            return {
                stage: 'banks',
                bank: undefined,
                transfer: undefined
            };
        },
        computed: {
            persistId() {
                return __filename.replace(/\?.*$/, '');
            },
            payment() {
                return this.transfer && this.transfer.payment;
            },
            isOk() {
                return this.payment && (this.payment.status === SUCCESS_STATUS || this.payment.status === PROCESS_STATUS);
            }
        },
        created() {
            window.addEventListener('beforeunload', this.beforeunloadMethods)
        },
        beforeDestroy() {
            window.removeEventListener('beforeunload', this.beforeunloadMethods)
        },

        methods: {
            beforeunloadMethods(e) { // блокируем окно от закрытия
                if (!this.$store.state.reload && this.isOk === undefined && !this.$store.state.error)
                {
                    e.preventDefault();
                }
            },
            isStage(stage) {
                return this.stage === stage;
            },
            isBank(bank) {
                return this.bank.code === bank;
            },
            goTo(stage) {
                this.stage = stage;
            },
            serialize() {
                return {
                    bank: this.bank,
                    transfer: this.transfer
                };
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            },
            prevBank(value) {
                this.bank = value;
                this.prev();
            },
            nextBank(value) {
                this.bank = value;
                this.confirmBank();
            },
            prevTransfer(value) {
                this.transfer = value;
                this.goTo('banks');
            },
            nextTransfer(value) {
                this.transfer = value;
                this.confirmTransfer();
            },
            confirmBank() {
                let data = {
                    payment_id: '',
                    card_pan: '',
                    bik: this.bank.bik,
                    status: PROCESS_STATUS,
                    detail: `Выбран банк ${this.bank.bik}, сумма ${this.amount} руб`
                };

                this.$store.dispatch('showLoader', { label: 'Отправка данных о выбранном банке' });

                banking.updateTransaction(this.transactionId, data).then((data) => {
                    this.goTo('transfer');
                }).catch((error) => {
                    const err = new Error('Не удалось отправить данные в учетную систему');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            confirmTransfer() {
                let data = {
                    payment_id: this.transfer.payment.id || '',
                    card_pan: this.transfer.card.pan || '',
                    bik: this.bank.bik,
                    status: this.transfer.payment.status !== undefined ? this.transfer.payment.status: this.transfer.payment.code,
                    detail: this.transfer.payment.detail
                };

                if (data.status === undefined) {
                    data.status = 99;
                }
                if (data.detail === undefined) {
                    data.detail = 'Неизвестная ошибка';
                }

                this.$store.dispatch('showLoader', { label: 'Отправка данных о результате перевода' });

                banking.updateTransaction(this.transactionId, data).then(() => {
                    this.next();
                }).catch((error) => {
                    const err = new Error('Не удалось отправить данные в учетную систему');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            }
        }
    };
</script>
