<template>
    <div class="app__block">
        <header-element>
            <button
                class="button button--darkyellow main__cancel"
                @click="submit"
            >
                Отправить платеж
            </button>
        </header-element>

        <main-element>
            <template v-slot:h2>
                Отправьте платеж
            </template>
            <payment-info :values="values" />
        </main-element>
    </div>
</template>

<script>
    import banking from '@/services/banking';
    import paymentInfo from './payment-info.vue';
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';

    export default {
        components: {
            paymentInfo,
            headerElement,
            mainElement
        },
        props: {
            transactionId: {
                type: String,
                required: true
            },
            bik: {
                type: String,
                required: true
            },
            inn: {
                type: String,
                required: true
            },
            card: {
                type: Object,
                required: true
            },
            amount: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                payment: undefined
            };
        },
        computed: {
            values() {
                return {
                    card_pan: this.card.pan,
                    amount: this.amount
                };
            }
        },
        methods: {
            prev() {
                this.$emit('prev', this.payment);
            },
            next() {
                this.$emit('next', this.payment);
            },
            submit() {
                this.$store.dispatch('showLoader', { label: 'Выполнение платежа' });
                banking.alfabank.executePayment(this.inn, {
                    order_id: this.transactionId,
                    card_pan: this.card.pan,
                    bik: this.bik,
                    amount: this.amount
                }).then((data) => {
                    this.payment = data;
                    this.next();
                }).catch((error) => {
                    this.$store.dispatch('hideLoader');
                    if (error.response && error.response.data) {
                        this.payment = error.response.data;
                    } else {
                        this.payment = {
                            detail: error.message
                        };
                    }
                    this.next();
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            }
        }
    };
</script>
