<template>
    <div class="app__block">
        <header-element>
            <button
                class="button button--darkyellow main__cancel"
                @click="submit"
            >
                Отправить платеж
            </button>
        </header-element>

        <main-element>
            <template v-slot:h2>
                Отправьте платеж
            </template>
            <payment-info :values="values" />
        </main-element>
    </div>
</template>

<script>
    import banking from '@/services/banking';
    import paymentInfo from './payment-info.vue';
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';

    export default {
        components: {
            paymentInfo,
            headerElement,
            mainElement
        },
        props: {
            transactionId: {
                type: String,
                required: true
            },
            bik: {
                type: String,
                required: true
            },
            inn: {
                type: String,
                required: true
            },
            card: {
                type: Object,
                required: true
            },
            amount: {
                type: Number,
                required: true
            },
            commentData: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                payment: undefined,
                order_mer: undefined
            };
        },
        computed: {
            values() {
                return {
                    card_pan: this.card.pan,
                    amount: this.amount
                };
            }
        },
        methods: {
            prev() {
                this.$emit('prev', this.payment);
            },
            next() {
                this.$emit('next', this.payment);
            },
            createPayment(order_p2p){
                this.$store.dispatch('showLoader', { label: 'Подтверждение выплаты' });
                banking.sberbank.createPayment(this.inn, {
                    transaction_id: this.transactionId,
                    card_pan: this.card.pan,
                    order_p2p: order_p2p,
                    order_mer: this.order_mer
                }).then((data) => {
                    this.payment = data;
                    this.next();
                }).catch((error) => {
                    this.$store.dispatch('hideLoader');
                    const err = new Error('Не удалось подтвердить выплату');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            createPaymentOrder(description) {
                this.$store.dispatch('showLoader', { label: 'Создание выплаты' });
                banking.sberbank.createPaymentOrder(this.inn, {
                    transaction_id: this.transactionId,
                    amount: this.amount,
                    description: description,
                    order_mer: this.order_mer
                }).then((order_p2p) => {
                    this.$store.dispatch('hideLoader');
                    return this.createPayment(order_p2p);
                }).catch((error) => {
                    this.$store.dispatch('hideLoader');
                    const err = new Error('Не удалось создать выплату');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                });
            },
            submit() {
                this.$store.dispatch('showLoader', { label: 'Резервирование суммы выплаты' });
                let pan_secret = this.card.pan.replace(/\s/g,"").replace(/(?<=\d{6})\d(?=\d{4})/g, '*');
                let description = `card ${pan_secret}, merchantID ${this.commentData}`;

                banking.sberbank.createOrder(this.inn, {
                    transaction_id: this.transactionId,
                    amount: this.amount,
                    description: description
                }).then((data) => {
                    this.$store.dispatch('hideLoader');
                    this.order_mer = data.order_mer;
                    return this.createPaymentOrder(description);
                }).catch((error) => {
                    this.$store.dispatch('hideLoader');
                    const err = new Error('Не удалось зарезервировать сумму выплаты');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                });
            }
        }
    };
</script>
