<template>
    <div />
</template>

<script>
    export default {
        created() {
            const id = this.$route.params.id;
            // const orderId = this.$router.currentRoute.query.orderId;
            // if (orderId) {
            //     this.$store.dispatch('nextStage', orderId);
            // }
            if (this.persistExists(id)) {
                this.$store.state.result = this.$route.query;
                this.$store.dispatch('reloadStop');
                this.persistLoad(id);
            } else {
                throw new Error(`Состояние ${id} не найдено`);
            }
        }
    };
</script>
