<template>
    <div class="app__block">
        <header-element>
            <button
                class="button button--darkyellow main__cancel"
                @click="createItem"
            >
                Добавить карту
            </button>
        </header-element>

        <main-element>
            <template v-slot:h2>
                Выберите карту из списка
            </template>
            <card-list
                :inn="inn"
                :items="items"
                :controls="controls"
                @control:select="selectItem"
            />
        </main-element>
    </div>
</template>

<script>
    import banking from '@/services/banking';
    import cardList from './card-list.vue';
    import headerElement from '@/components/header-element.vue';
    import mainElement from '@/components/main-element.vue';

    export default {
        components: {
            cardList,
            headerElement,
            mainElement
        },
        props: {
            clientId: {
                type: String,
                required: true
            },
            inn: {
                type: String,
                required: true
            },
            card: {
                type: Object,
                default: undefined
            },
            phone: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                idx: -1,
                items: [],
                controls: [
                    { event: 'select', label: 'Выбрать', color: 'green' },
                ],
            };
        },
        created() {
            this.getItems();
        },
        methods: {
            getItems() {
                this.$store.dispatch('showLoader', { label: 'Получение списка карт' });
                banking.chelinvest.getCards(this.inn, this.clientId).then((items) => {
                    this.items = items;
                }).catch((error) => {
                    const err = new Error('Не удалось получить карты');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            createItem() {
                this.$store.dispatch('showLoader', { label: 'Добавление новой карты' });
                banking.chelinvest.createCard(this.inn, this.clientId, this.phone, {}).then((data) => {
                    this.persistSave('card');
                    this.$store.dispatch('reloadStart');
                    document.location.href = data.url;
                }).catch((error) => {
                    const err = new Error('Не удалось добавить карту');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            selectItem(idx) {
                const item = this.items[idx];
                this.value = item;
                this.next();
            },
            prev() {
                this.$emit('prev', this.value);
            },
            next() {
                this.$emit('next', this.value);
            }
        }
    };
</script>
