import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        error: null,
        loader: null,
        reload: false,
    },
    mutations: {
        user(state, user) {
            state.user = user;
        },
        error(state, error) {
            state.error = error;
        },
        loader(state, loader) {
            state.loader = loader;
        },
        reload(state, reload) {
            state.reload = reload;
        },
    },
    actions: {
        authUser(context, user) {
            context.commit('user', user);
        },
        deauthUser(context) {
            context.commit('user', null);
        },
        showError(context, error) {
            context.commit('error', error);
        },
        hideError(context) {
            context.commit('error', null);
        },
        showLoader(context, loader) {
            context.commit('loader', loader);
        },
        hideLoader(context) {
            context.commit('loader', null);
        },
        reloadStart(context) {
            context.commit('reload', true);
        },
        reloadStop(context) {
            context.commit('reload', false);
        },

    }
});
