<template>
    <modal :is-shown="!!payment">
        <template v-if="payment">
            <template v-if="isOk">
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Перевод успешно завершен
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <p>Денежный перевод успешно завершен</p>
                    <p>Закройте окно браузера, операция завершена</p>
                </div>
            </template>
            <template v-else>
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Ошибка при переводе
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <p v-if="payment.detail">
                        <strong>{{ payment.detail }}</strong>
                    </p>
                    <p>При осуществлении перевода произошла ошибка</p>
                    <p>Закройте окно браузера, операция завершена</p>
                    <p>
                        <strong>Для получения дополнительной информации обратитесь в техническую поддержку</strong>
                    </p>
                </div>
            </template>
        </template>
    </modal>
</template>

<script>
    import modal from '@/components/modal.vue';

    export default {
        components: {
            modal
        },
        props: {
            payment: {
                type: Object,
                default: undefined
            },
            isOk: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
