<template>
    <div class="app__bank">
        <card
            v-if="isStage('card')"
            :card="card"
            @prev="prevCard"
            @next="nextCard"
        />
        <verify
            v-if="isStage('verify')"
            :inn="inn"
            :card="card"
            :amount="amount"
            @prev="prevVerify"
            @next="nextVerify"
        />
        <confirm
            v-if="isStage('confirm')"
            :transaction-id="transactionId"
            :card="card"
            :amount="amount"
            @prev="prevConfirm"
            @next="nextConfirm"
        />
        <payment
            v-if="isStage('payment')"
            :transaction-id="transactionId"
            :bik="bik"
            :inn="inn"
            :card="card"
            :amount="amount"
            @prev="prevPayment"
            @next="nextPayment"
        />
    </div>
</template>

<script>
    import card from './card.vue';
    import verify from '../verify.vue';
    import confirm from '../confirm.vue';
    import payment from './payment.vue';

    export default {
        components: {
            card,
            verify,
            confirm,
            payment
        },
        props: {
            transactionId: {
                type: String,
                required: true
            },
            clientId: {
                type: String,
                required: true
            },
            bik: {
                type: String,
                required: true
            },
            inn: {
                type: String,
                required: true
            },
            amount: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                stage: 'card',
                card: undefined,
                verify: undefined,
                confirm: undefined,
                payment: undefined
            };
        },
        computed: {
            persistId() {
                return __filename.replace(/\?.*$/, '');
            }
        },
        methods: {
            isStage(stage) {
                return this.stage === stage;
            },
            goTo(stage) {
                this.stage = stage;
            },
            serialize() {
                return {
                    card: this.card,
                    verify: this.verify,
                    confirm: this.confirm,
                    payment: this.payment
                };
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            },
            prevCard(value) {
                this.card = value;
                this.prev();
            },
            nextCard(value) {
                this.card = value;
                this.goTo('verify');
            },
            prevVerify(value) {
                this.verify = value;
                this.goTo('card');
            },
            nextVerify(value) {
                this.verify = value;
                this.goTo('confirm');
            },
            prevConfirm(value) {
                this.confirm = value;
                this.goTo('verify');
            },
            nextConfirm(value) {
                this.confirm = value;
                this.goTo('payment');
            },
            prevPayment(value) {
                this.payment = value;
                this.goTo('confirm');
            },
            nextPayment(value) {
                this.payment = value;
                this.next();
            }
        }
    };
</script>
